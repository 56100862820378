import {Score} from "./score";

export const ScoreLabelDigitization: {
    [key in Score]: string;
} = {
    [Score.LevelNoData]: 'kann ich aktuell nicht beantworten',
    [Score.Level1]: 'nicht digital',
    [Score.Level2]: 'überwiegend nicht digital',
    [Score.Level3]: 'teilweise digital',
    [Score.Level4]: 'überwiegend digital',
    [Score.Level5]: 'voll digital',
}