import {Category} from "./category";

export const Categories = [
    Category.Technology,
    Category.Data,
    Category.Quality,
    Category.Customers,
    Category.Company,
];

