import {Group} from "./group";

export const Groups = [
    Group.Techbasis,
    Group.Tools,
    Group.Integration,

    Group.Datasourcing,
    Group.Dataprovision,
    Group.Datausage,

    Group.Description,
    Group.Execution,
    Group.Compliance,

    Group.Centering,
    Group.Usage,
    Group.Partizipation,

    Group.DigitalSkills,
    Group.DigitalLeadership,
    Group.DigitalMindset,
]

