import {Score} from "./score";

export const Scores = [
    Score.LevelNoData,
    Score.Level1,
    Score.Level2,
    Score.Level3,
    Score.Level4,
    Score.Level5,
]

