import React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import styles from './process.module.scss';
import {Container} from '../../components/container/container';
import {Card} from '../../components/card/card';
import ProcessIllustration from '../../assets/process.svg';
import {selectProcessData, selectShowErrors, setProcessData, setShowErrors} from "../../features/app/app-slice";
import {ProcessData} from "../../models/process-data";
import {FormField as FormFieldModel} from "../../models/form-field";
import {FormField} from '../../components/form-field/form-field';

const processFields: FormFieldModel<ProcessData>[] = [
    {
        field: 'processName',
        label: 'Name des Geschäftsprozesses',
        placeholder: 'Verarbeitung Kundensupportanfrage',
        hint: 'Um welchen Geschäftsprozess handelt es sich?',
        type: 'text',
        required: true,
    },

    {
        field: 'processDescription',
        label: 'Kurzbeschreibung des Geschäftsprozesses',
        placeholder: '- Ein Kunde schickt eine Supportanfrage\n- Der First-Level-Support bearbeitet die Anfrage\n- Der Kunde erhält eine Rückmeldung mit möglichen Lösungsvorschlägen',
        hint: 'Wie läuft der Prozess im groben ab? Welche Schritte werden abgearbeitet?',
        type: 'textarea-large',
    },

    {
        field: 'departments',
        label: 'Beteiligte Organisationseinheiten',
        placeholder: '- First-Level-Support\n- Fachabteilung',
        hint: 'Welche Abteilungen/Organisationseinheiten sind an diesem Prozess beteiligt?',
        type: 'textarea',
    },

    {
        field: 'users',
        label: 'Nutzer:innen',
        placeholder: '- Endkunden\n- Mitarbeiter:innen im First-Level-Support',
        hint: 'Welche Nutzer:innen sind an diesem Prozess beteiligt?',
        type: 'textarea',
    },

    {
        field: 'relatedProcesses',
        label: 'Verwandte Prozesse (Vor- und Folgeprozesse)',
        placeholder: '- Softwareentwicklungsprozess',
        hint: 'Welche Prozesse sind mit diesem Prozess verwandt? Gibt es vor- oder nachgelagerte Prozesse?',
        type: 'textarea',
    },
];

export function Process() {
    const dispatch = useAppDispatch();

    const process: ProcessData = useAppSelector(selectProcessData);
    const showErrors = useAppSelector(selectShowErrors);

    const handleProcessDataChange = (update: Partial<ProcessData>) => {
        dispatch(setProcessData(update));
        dispatch(setShowErrors(false));
    };

    const getFieldError = (field: FormFieldModel<ProcessData>) => {
        if (showErrors && !validateProcessField(field, process)) {
            return 'Bitte fülle dieses Feld aus.';
        }
        return undefined;
    }

    return (
        <>
            <Container className={styles.container}>
                <div
                    className={styles.fieldsContainer}
                >
                    {
                        processFields.map(field => (
                            <FormField
                                key={field.field}
                                field={field}
                                container={process}
                                onChange={handleProcessDataChange}
                                error={getFieldError(field)}
                            />
                        ))
                    }
                </div>
                <div className={styles.cardContainer}>
                    <Card className={styles.card}>
                        <h1 className={styles.title}>Über den Prozess</h1>
                        <p>
                            Beschreibe kurz den Geschäftsprozess, für den du den digitalen Reifegrad bestimmen möchtest.
                        </p>
                        <p>
                            Achte darauf, dass du immer nur einen einzelnen Geschäftsprozess gleichzeitig
                            untersuchst.
                            So kannst du bessere Aussagen über deinen Digitalisierungsgrad treffen.
                        </p>
                        <div className={styles.imgContainer}>
                            <img
                                src={ProcessIllustration}
                                alt="Prozess"
                            />
                        </div>
                    </Card>
                </div>
            </Container>
        </>
    );
}

export function validateProcessPage(process: ProcessData): boolean {
    return processFields.every(f => validateProcessField(f, process));
}

function validateProcessField(field: FormFieldModel<ProcessData>, process: ProcessData): boolean {
    if (!field.required) {
        return true;
    }
    const val = process[field.field];
    return val != null && val.trim().length > 0;
}