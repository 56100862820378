import {Container} from '../container/container';
import styles from './app-actions.module.scss';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {nextPage, previousPage, reset, selectCurrentPage, setIsFastTrack} from "../../features/app/app-slice";
import {Button} from "../button/button";
import {Legal} from "../legal/legal";

export function AppActions({className}: { className?: string; }) {
    const dispatch = useAppDispatch();

    const currentPage = useAppSelector(selectCurrentPage);

    if (currentPage === 'intro') {
        return null;
    }

    const handleNext = () => {
        dispatch(nextPage());
    };

    const handleNextNormalTrack = () => {
        dispatch(setIsFastTrack(false));
        handleNext();
    };

    const handleNextFastTrack = () => {
        dispatch(setIsFastTrack(true));
        handleNext();
    };

    const handlePrevious = () => {
        dispatch(previousPage());
    };

    const handleRestart = () => {
        dispatch(reset());
    };

    const handlePdf = () => {
        window.print();
    };

    return (
        <div className={styles.wrapper + (className ? ' ' + className : '')}>
            <Container className={styles.container}>
                <div className={styles.buttonContainer}>
                    {
                        currentPage !== 'summary' &&
                        currentPage !== 'process' &&
                        <Button
                            label="Weiter"
                            onClick={handleNext}
                            className={styles.button}
                        />
                    }
                    {
                        currentPage === 'summary' &&
                        <Button
                            label="Neu Starten"
                            onClick={handleRestart}
                            className={styles.button}
                        />
                    }
                    {
                        currentPage === 'process' &&
                        <Button
                            label="Weiter"
                            onClick={handleNextNormalTrack}
                            className={styles.button + ' ' + styles.nextButtonDesktop}
                        />
                    }
                    {
                        currentPage === 'process' &&
                        <Button
                            label="Weiter (Minimaler Modus)"
                            onClick={handleNextFastTrack}
                            className={styles.backButton + ' ' + styles.button + ' ' + styles.nextButtonDesktop}
                        />
                    }
                    {
                        currentPage === 'process' &&
                        <Button
                            label="Weiter"
                            onClick={handleNextFastTrack}
                            className={styles.button + ' ' + styles.nextButtonPhone}
                        />
                    }

                        <Button
                            label="Zurück"
                            onClick={handlePrevious}
                            className={styles.backButton + ' ' + styles.button}
                        />

                    {
                        currentPage === 'summary' &&
                        <Button
                            label="PDF erzeugen"
                            onClick={handlePdf}
                            className={styles.backButton + ' ' + styles.button}
                        />
                    }
                </div>

                <div className={styles.legalLinks}>
                    <Legal/>
                </div>
            </Container>
        </div>
    );
}
