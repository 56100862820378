import {Pie, PieChart, ResponsiveContainer} from "recharts";
import React from "react";
import {Groups} from "../../data/group/groups";
import {Questions} from "../../data/questions/questions";
import {CategoryColorDark} from "../../data/category/category-color-dark";
import {Category} from "../../data/category/category";
import {GroupLabel} from "../../data/group/group-label";
import {Group} from "../../data/group/group";
import {useAppSelector} from "../../hooks";
import {selectScores} from "../../features/app/app-slice";
import {calculateScore} from "../../utils/calculate-score";

export function Chart() {
    const scores = useAppSelector(selectScores);

    const data = [];

    for (const group of Groups) {
        const scoreSum = calculateScore(scores, undefined, group);// Questions.reduce((acc, cr, index) => acc + (cr.group === group ? scores[index] ?? 0 : 0), 0);
        const crit = Questions.find(cr => cr.group === group);
        data.push({
            color: CategoryColorDark[crit?.category ?? Category.Quality],
            name: GroupLabel[crit?.group ?? Group.Description],
            score: scoreSum,
            relativeScore: scoreSum,
        });
    }

    const angle = 360 / Groups.length;

    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    data={[{width: 1}, {width: 1}, {width: 1}, {width: 1}]}
                    dataKey="width"
                    cx="50%"
                    cy="50%"
                    outerRadius="76%"
                    fill="#fafafa"
                    isAnimationActive={false}
                />
                {
                    data.map((d, index) => (
                        <React.Fragment key={index}>
                            <Pie
                                data={[{width: 1}]}
                                key={'inner ' + index}
                                startAngle={index * angle}
                                endAngle={(index + 1) * angle}
                                dataKey="width"
                                cx="50%"
                                cy="50%"
                                outerRadius={(74 * d.relativeScore) + '%'}
                                fill={d.color}
                            />
                            <Pie
                                data={[{width: 1}]}
                                key={'outer ' + index}
                                startAngle={index * angle}
                                endAngle={(index + 1) * angle}
                                dataKey="width"
                                cx="50%"
                                cy="50%"
                                label={({x, y, textAnchor}) => {
                                    return (
                                        <text
                                            x={x}
                                            y={y}
                                            fontSize={12}
                                            color="black"
                                            textAnchor={textAnchor}
                                        >
                                            {d.name}
                                        </text>
                                    )
                                }}
                                innerRadius="78%"
                                outerRadius="80%"
                                fill={d.color}
                            />
                        </React.Fragment>
                    ))
                }
            </PieChart>
        </ResponsiveContainer>
    );
}