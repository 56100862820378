import {Category} from "../category/category";
import {Group} from "../group/group";
import {ScoringType} from "../scoring-type";
import {Question} from "../../models/question";

export const qualityQuestions: Question[] = [
    {
        category: Category.Quality,
        group: Group.Description,
        title: 'Der Prozess ist mithilfe von Standards vollständig dokumentiert (Fokus: Dokumentation).',
        hint: 'Mögliche Standards für die Dokumentation sind: BPMN, EPK oder UML.',
        type: ScoringType.Digitalization,
    },
    {
        category: Category.Quality,
        group: Group.Description,
        title: 'Der Prozess ist mithilfe von Standards vollständig beschrieben (Fokus: Arbeitsablaufbeschreibung).',
        hint: '',
        type: ScoringType.Digitalization,
    },

    {
        category: Category.Quality,
        group: Group.Execution,
        title: 'Der Status des Prozesses ist jederzeit aus der Sicht eines anderen Bereichs einsehbar.',
        hint: 'Sofern der Prozessstatus keine schützenswerte Information ist, sollte dieser im Sinne der Transparenz zugänglich sein.',
        type: ScoringType.Digitalization,
    },
    {
        category: Category.Quality,
        group: Group.Execution,
        title: 'Die Stabilität der Prozessdurchläufe ist auch bei Lastspitzen sichergestellt.',
        hint: '',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Quality,
        group: Group.Compliance,
        title: 'Der Prozess beinhaltet Kontrollen und Prüfinstanzen, um die Einhaltung der regulatorischen Anforderungen sicherzustellen (intern).',
        hint: 'Regulatorische Anforderungen können beispielsweise eine Compliance-Richtlinie oder die Einhaltung bestimmter Prozesszeiten sein.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Quality,
        group: Group.Compliance,
        title: 'Der Prozess stellt die regulatorischen Anforderungen an Datenschutz und Datensicherheit vollständig sicher (extern).',
        hint: '',
        type: ScoringType.Opinion,
    },
];