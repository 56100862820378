import {Category} from "./category";

export const CategoryColor: {
    [key in Category]: string;
} = {
    [Category.Technology]: '#E3F3E3',
    [Category.Data]: '#E8F4FF',
    [Category.Quality]: '#F9F3DD',
    [Category.Customers]: '#eeddf9',
    [Category.Company]: '#FFEDE3',
}