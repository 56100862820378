import {CardProps} from './card-props';
import styles from './card.module.scss';

export function Card({className, children}: CardProps) {
    return (
        <div className={styles.card + (className ? ' ' + className : '')}>
            {children}
        </div>
    );
}
