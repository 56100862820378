import styles from "./question-details.module.scss";
import {ScoreSelect} from "../score-select/score-select";
import {FormField} from "../form-field/form-field";
import {selectDescription, selectIsFastTrack, setDescription} from "../../features/app/app-slice";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {Questions} from "../../data/questions/questions";

export function QuestionDetails({idx, className}: { idx: number, className?: string }) {
    const dispatch = useAppDispatch();

    const question = Questions[idx];

    const description = useAppSelector(selectDescription(idx));
    const isFastTrack = useAppSelector(selectIsFastTrack);

    return (
        <div className={className}>
            <h1 className={styles.title}>
                {question?.title}
            </h1>
            <p>
                {question?.hint}
            </p>

            <ScoreSelect
                className={styles.select}
                label="Deine Einschätzung"
                idx={idx}
            />

            {
                !isFastTrack &&
                <FormField
                    className={styles.description}
                    field={{
                        field: 'desc',
                        label: 'Deine Begründung (Optional)',
                        type: 'textarea',
                        placeholder: 'Die Einschätzung ergibt sich aufgrund der nachfolgenden Punkte:\n\n- Punkt 1\n- Punkt 2',
                        hint: 'Begründe deine Einschätzung für eine bessere Nachvollziehbarkeit.',
                    }}
                    container={{
                        desc: description,
                    }}
                    onChange={({desc}) => {
                        dispatch(setDescription({idx, desc: desc ?? ''}));
                    }}
                />
            }
        </div>
    );
}