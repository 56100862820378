import {Score} from "./score";

export const ScoreLabelOpinion: {
    [key in Score]: string;
} = {
    [Score.LevelNoData]: 'kann ich aktuell nicht beantworten',
    [Score.Level1]: 'stimme nicht zu',
    [Score.Level2]: 'stimme überwiegend nicht zu',
    [Score.Level3]: 'stimme teilweise zu',
    [Score.Level4]: 'stimme überwiegend zu',
    [Score.Level5]: 'stimme voll zu',
}