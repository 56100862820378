import {ContainerProps} from './container-props';
import styles from './container.module.scss';

export function Container({className, children}: ContainerProps) {
    return (
        <div className={styles.container + (className ? ' ' + className : '')}>
            {children}
        </div>
    );
}
