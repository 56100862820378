import {Category} from "./category";

export const CategoryColorDark: {
    [key in Category]: string;
} = {
    [Category.Technology]: '#99f299',
    [Category.Data]: '#9ccdff',
    [Category.Quality]: '#fae493',
    [Category.Customers]: '#d193fa',
    [Category.Company]: '#ffbb96',
}