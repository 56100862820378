import {Group} from "./group";

export const GroupLabel: {
    [key in Group]: string;
} = {
    [Group.Techbasis]: 'Technologiebasis',
    [Group.Tools]: 'Tools im Prozess',
    [Group.Integration]: 'Systemintegration',

    [Group.Datasourcing]: 'Datenerhebung',
    [Group.Dataprovision]: 'Datenbereitstellung',
    [Group.Datausage]: 'Datenverwendung',

    [Group.Description]: 'Beschreibung',
    [Group.Execution]: 'Ausführung',
    [Group.Compliance]: 'Compliance',

    [Group.Centering]: 'Zentrierung',
    [Group.Usage]: 'Nutzen',
    [Group.Partizipation]: 'Partizipation',

    [Group.DigitalSkills]: 'Digital Skills',
    [Group.DigitalLeadership]: 'Digital Leadership',
    [Group.DigitalMindset]: 'Digital Mindset',
}