import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../store';
import {ProcessData} from "../../models/process-data";
import {Score} from "../../data/score/score";
import {AppPage} from "../../data/app-page";
import {Questions} from "../../data/questions/questions";
import {AppState} from "../../models/app-state";
import {validateProcessPage} from "../../pages/process/process";
import {validateQuestionPage} from "../../pages/question/question";
import {ScoreMap} from "../../data/score-map";
import {DescriptionMap} from "../../data/description-map";


const initialState: AppState = {
    currentPage: 'intro',
    isFastTrack: false,
    showErrors: false,
    processData: {
        processName: '',
        departments: '',
        users: '',
        processDescription: '',
        relatedProcesses: '',
    },
    scores: {},
    descriptions: {},
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<AppPage>) => {
            state.currentPage = action.payload;
        },
        nextPage: (state) => {
            state.showErrors = false;
            switch (state.currentPage) {
                case 'intro':
                    state.currentPage = 'process';
                    break;
                case 'process': {
                    const isValid = validateProcessPage(state.processData);
                    if (isValid) {
                        state.currentPage = 0;
                    } else {
                        state.showErrors = true;
                    }
                    break;
                }
                case 'summary':
                    // Will not happen
                    break;
                default: {
                    const isValid = validateQuestionPage(state.currentPage, state.scores);
                    if (isValid) {
                        if (state.currentPage >= Questions.length - 1) {
                            state.currentPage = 'summary';
                        } else {
                            state.currentPage = state.currentPage + 1;
                        }
                    } else {
                        state.showErrors = true;
                    }
                    break;
                }
            }
        },
        previousPage: (state) => {
            state.showErrors = false;
            switch (state.currentPage) {
                case 'intro':
                    // Will not happen
                    break;
                case 'process':
                    state.currentPage = 'intro';
                    break;
                case 'summary':
                    state.currentPage = Questions.length - 1;
                    break;
                default:
                    if (state.currentPage === 0) {
                        state.currentPage = 'process';
                    } else {
                        state.currentPage = state.currentPage - 1;
                    }
                    break;
            }
        },

        setIsFastTrack: (state, action: PayloadAction<boolean>) => {
            state.isFastTrack = action.payload;
        },
        setShowErrors: (state, action: PayloadAction<boolean>) => {
            state.showErrors = action.payload;
        },

        setProcessData: (state, action: PayloadAction<Partial<ProcessData>>) => {
            state.processData = {
                ...state.processData,
                ...action.payload,
            };
        },

        setScore: (state, action: PayloadAction<{ idx: number, score: Score }>) => {
            state.scores = {
                ...state.scores,
                [action.payload.idx]: action.payload.score,
            };
        },
        setScores: (state, action: PayloadAction<ScoreMap>) => {
            state.scores = action.payload;
        },

        setDescription: (state, action: PayloadAction<{ idx: number, desc: string }>) => {
            state.descriptions = {
                ...state.descriptions,
                [action.payload.idx]: action.payload.desc,
            };
        },
        setDescriptions: (state, action: PayloadAction<DescriptionMap>) => {
            state.descriptions = action.payload;
        },

        reset: (state) => {
            state.currentPage = 'intro';
            state.isFastTrack = false;
            state.showErrors = false;
            state.processData = {
                processName: '',
                departments: '',
                users: '',
                processDescription: '',
                relatedProcesses: '',
            };
            state.scores = {};
            state.descriptions = {};
        },
    },
});

export const {
    setPage,
    nextPage,
    previousPage,

    setIsFastTrack,
    setShowErrors,

    setProcessData,

    setScore,
    setScores,
    setDescription,
    setDescriptions,

    reset,
} = appSlice.actions;

export const selectCurrentPage: (state: RootState) => AppPage = (state: RootState) => state.app.currentPage;
export const selectIsFastTrack: (state: RootState) => boolean = (state: RootState) => state.app.isFastTrack;
export const selectShowErrors: (state: RootState) => boolean = (state: RootState) => state.app.showErrors;

export const selectProcessData: (state: RootState) => ProcessData = (state: RootState) => state.app.processData;

export const selectScore = (idx: number) => (state: RootState) => state.app.scores[idx];
export const selectScores = (state: RootState) => state.app.scores;

export const selectDescription = (idx: number) => (state: RootState) => state.app.descriptions[idx];
export const selectDescriptions = (state: RootState) => state.app.descriptions;

export default appSlice.reducer;
