import {Category} from "../category/category";
import {Group} from "../group/group";
import {ScoringType} from "../scoring-type";
import {Question} from "../../models/question";

export const skillsQuestions: Question[] = [
    {
        category: Category.Company,
        group: Group.DigitalSkills,
        title: 'Die im Prozess involvierten Mitarbeiter:innen besitzen die Kompetenzen, um den Prozess erfolgreich durchzuführen.',
        hint: 'Beispiel: Früher wurden Supportanfragen telefonisch aufgenommen. Heute werden diese automatisiert in ein Ticketing-System gespielt. Haben deine Mitarbeiter:innen die notwendigen Qualifikationen, um mit einem solchen System produktiv zu arbeiten?',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Company,
        group: Group.DigitalSkills,
        title: 'Es steht vollständige digitale Kompetenz (intern oder extern) zur Verfügung, um den Prozess erfolgreich weiterzuentwickeln.',
        hint: '',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Company,
        group: Group.DigitalLeadership,
        title: 'Die im Prozess beteiligten Führungskräfte denken selbst vorrangig in digitalen Lösungen.',
        hint: '',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Company,
        group: Group.DigitalLeadership,
        title: 'In der Organisation werden für die Beschäftigten wirksame Maßnahmen ergriffen, um Leistungen/Services im digitalisierten Umfeld zu fördern (Veränderungsmanagement).',
        hint: '',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Company,
        group: Group.DigitalMindset,
        title: 'Die im Prozess beteiligten Mitarbeiter:innen wirken in einem Umfeld, in der eine Fehlerkultur (Experimentalkultur) gefördert wird.',
        hint: '',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Company,
        group: Group.DigitalMindset,
        title: 'Digitale Ansätze sind in der Organisation bei der Lösung von Problemen stets erste Wahl (Digital First).',
        hint: '',
        type: ScoringType.Opinion,
    },
];