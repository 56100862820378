import {Category} from "../category/category";
import {Group} from "../group/group";
import {ScoringType} from "../scoring-type";
import {Question} from "../../models/question";

export const techQuestions: Question[] = [
    {
        category: Category.Technology,
        group: Group.Techbasis,
        title: 'Alle eingehenden Informationen für den Prozess sind vollständig digital.',
        hint: 'Die Informationen werden vollständig digital bereitgestellt, wenn diese in maschinenlesbarer Form durch die jeweiligen Vorsysteme zur Verfügung gestellt werden. Beispiel: Ein Formular für Supportanfragen kann direkt Online ausgefüllt und an das Folgesystem abgesendet werden und muss nicht via E-Mail eingesendet werden.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Technology,
        group: Group.Techbasis,
        title: 'Alle ausgehenden Informationen des Prozesses sind vollständig digital.',
        hint: 'Die Informationen werden vollständig digital bereitgestellt, wenn diese in maschinenlesbarer Form an die jeweiligen Folgesysteme weitergeleitet werden können. Beispiel: Die Dokumentation der Bearbeitung einer eingegangenen Supportanfrage wird automatisiert an Folgesysteme zur Auswertung übergeben.',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Technology,
        group: Group.Tools,
        title: 'Es wird eine Software-Lösung zur vollständigen Modellierung und Analyse des Geschäftsprozesses eingesetzt.',
        hint: 'Es muss eine adäquate Software-Lösung eingesetzt werden, welche zur Modellierung von Prozessen geeignet ist und den Prozess idealerweise auch maschinenlesbar zugänglich macht. Beispiel: Eine Software, welche Business-Process-Modeling-Notation (BPMN) verwendet. PowerPoint hingegen ist kein Modellierungstool.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Technology,
        group: Group.Tools,
        title: 'Der Geschäftsprozess ist mit einer Software-Lösung vollständig automatisiert.',
        hint: 'Eine vollständige Automatisierung liegt vor, wenn nach aktuellem technischen Stand keine weiteren manuellen Tätigkeiten automatisiert werden können. Beispiel: Eine Supportanfrage wird automatisiert einer Person zur Bearbeitung zugeordnet. Die Beantwortung erfolgt weiterhin manuell durch die Person.',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Technology,
        group: Group.Integration,
        title: 'Alle im Prozess verwendeten Software-Lösungen sind vollständig integriert.',
        hint: 'Eine vollständige Integration liegt vor, wenn jegliche Daten über digitale Schnittstellen laufen. Beispiel: Die Kundenanfrage erfolgt in einem Formular, welches die Daten über eine digitale Schnittstelle direkt an ein Ticketing-System weitergibt woraufhin ein Support-Ticket eröffnet wird.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Technology,
        group: Group.Integration,
        title: 'Der Prozess läuft vollständig ohne unnötige Medienbrüche.',
        hint: 'Ein Medienbruch findet statt, wenn beispielsweise vom Medium Online-Formular auf das Medium Papier gewechselt wird. Es gilt im Allgemeinen, Medienbrüche zu vermeiden.',
        type: ScoringType.Opinion,
    },
];