import {CategorySummaryProps} from "./category-summary-props";
import styles from "./category-summary.module.scss";
import {CategoryLabel} from "../../data/category/category-label";
import {Questions} from "../../data/questions/questions";
import {GroupLabel} from "../../data/group/group-label";
import {ScoringType} from "../../data/scoring-type";
import {ScoreLabelOpinion} from "../../data/score/score-label-opinion";
import {ScoreLabelDigitization} from "../../data/score/score-label-digitization";
import React, {useState} from "react";
import {useAppSelector} from "../../hooks";
import {selectDescriptions, selectScores} from "../../features/app/app-slice";
import Chevron from '../../assets/icons/expand-more.svg';
import {cns} from "../../utils/class-names";
import {CategoryColor} from "../../data/category/category-color";
import {Score} from "../../data/score/score";
import {calculateScore} from "../../utils/calculate-score";

export function CategorySummary({category, className}: CategorySummaryProps) {
    const allScores = useAppSelector(selectScores);
    const allDescriptions = useAppSelector(selectDescriptions);

    const [isCollapsed, setIsCollapsed] = useState(true);

    const questions = Questions
        .filter(qs => qs.category === category);

    const scores = Questions
        .map((qs, index) => qs.category === category ? allScores[index] : null)
        .filter((sc): sc is Score => sc != null);
    const descriptions = Questions
        .map((qs, index) => qs.category === category ? allDescriptions[index] : null)
        .filter((sc): sc is string => sc != null);

    const groups = Array.from(new Set(questions.map(qs => qs.group)));

    const averageScore = calculateScore(allScores, category);
    const groupScore = groups.reduce((acc, gr) => ({
        ...acc,
        [gr]: calculateScore(allScores, category, gr),
    }), {}) as {[key: number]: number};

    return (
        <div className={className}>
            <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className={styles.header}
                style={{
                    backgroundColor: CategoryColor[category],
                }}
            >
                <img
                    className={cns({
                        [styles.collapseIcon]: true,
                        [styles.open]: !isCollapsed,
                    })}
                    src={Chevron}
                    alt="Öffnen / Schließen"
                />

                <h3 className={styles.categoryHeadline}>
                    {CategoryLabel[category]}: {Math.round(averageScore * 100)}%
                </h3>
            </button>

            <div
                className={cns({
                    [styles.collapseContainer]: true,
                    [styles.isCollapsed]: isCollapsed,
                })}
            >
                {
                    groups.map((gr, grIndex) => (
                        <div
                            key={gr}
                            className={styles.groupContainer}
                        >
                            <h4 className={styles.groupHeadline}>
                                {GroupLabel[gr]} {Math.round(groupScore[gr] * 100)}%
                            </h4>
                            <ul className={styles.groupDetails}>
                                {
                                    questions
                                        .filter(qs => qs.group === gr)
                                        .map((qs, qsIndex) => (
                                            <li
                                                key={qs.title}
                                                className={styles.questionContainer}
                                            >
                                                <div>
                                                    {qs.title}
                                                </div>
                                                <div>
                                                    <b>{
                                                        qs.type === ScoringType.Opinion ?
                                                            ScoreLabelOpinion[scores[grIndex * grIndex + qsIndex]] :
                                                            ScoreLabelDigitization[scores[grIndex * grIndex + qsIndex]]
                                                    }</b>
                                                </div>
                                                {
                                                    descriptions[grIndex * grIndex + qsIndex] &&
                                                    descriptions[grIndex * grIndex + qsIndex].trim().length > 0 &&
                                                    <div className={styles.note}>
                                                        <pre>{descriptions[grIndex * grIndex + qsIndex]}</pre>
                                                    </div>
                                                }
                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}