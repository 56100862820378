import React from 'react';
import {useAppSelector} from '../../hooks';
import {Container} from '../../components/container/container';
import styles from './question.module.scss';
import animations from './question-animations.module.scss';
import {Progress} from "../../components/progress/progress";
import {selectCurrentPage} from "../../features/app/app-slice";
import {ScoreMap} from "../../data/score-map";
import {QuestionHeroCard} from "../../components/question-hero-card/question-hero-card";
import {QuestionDetails} from "../../components/question-details/question-details";
import {Questions} from "../../data/questions/questions";

export function Question() {
    const page = useAppSelector(selectCurrentPage);

    if (typeof page === 'string') {
        return null;
    }

    const prevPage = page === 0 ? undefined : page - 1;
    const nextPage = page === Questions.length - 1 ? undefined : page + 1;

    return (
        <Container className={styles.container}>
            <div className={styles.contentWrapper}>
                <Progress/>

                <div className={styles.animationContainer}>
                    {
                        prevPage != null &&
                        <QuestionDetails
                            key={prevPage}
                            idx={prevPage}
                            className={animations.prevPageSlide}
                        />
                    }

                    <QuestionDetails
                        key={page}
                        idx={page}
                        className={animations.currentPageSlide}
                    />

                    {
                        nextPage != null &&
                        <QuestionDetails
                            key={nextPage}
                            idx={page}
                            className={animations.nextPageSlide}
                        />
                    }
                </div>
            </div>
            <div className={styles.heroWrapper}>
                <QuestionHeroCard
                    key={page}
                    idx={page}
                />
            </div>
        </Container>
    );
}

export function validateQuestionPage(currentQuestionIndex: number, scores: ScoreMap): boolean {
    return scores[currentQuestionIndex] != null;
}
