export enum Group {
    Techbasis,
    Tools,
    Integration,

    Datasourcing,
    Dataprovision,
    Datausage,

    Description,
    Execution,
    Compliance,

    Centering,
    Usage,
    Partizipation,

    DigitalSkills,
    DigitalLeadership,
    DigitalMindset,
}

