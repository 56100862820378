import {Category} from "./category";

export const CategoryLabel: {
    [key in Category]: string;
} = {
    [Category.Technology]: 'Technologie',
    [Category.Data]: 'Prozessdaten',
    [Category.Quality]: 'Prozessqualität',
    [Category.Customers]: 'Nutzer:innen',
    [Category.Company]: 'Organisationskultur',
}