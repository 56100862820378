import {Group} from "./group";

export const GroupDescription: {
    [key in Group]: string;
} = {
    [Group.Techbasis]: 'Die „Technologiebasis“ gibt darüber Auskunft, ob die In- und Output-Kanäle analog (z.B. papierbasiert) oder digital (z.B. Webanwendung) sind.',
    [Group.Tools]: 'Das Kriterium „Prozesstools“ bewertet, inwieweit digitale Werkzeuge im Prozess eingesetzt werden.',
    [Group.Integration]: '„Systemintegration“ bewertet, in welcher Qualität die technischen Lösungen miteinander verbunden sind.',

    [Group.Datasourcing]: 'Die „Datenerhebung“ beschreibt, inwiefern die Daten des Prozesses digital erhoben und archiviert werden.',
    [Group.Dataprovision]: '„Datenbereitstellung“ bewertet die Qualität und Nutzbarkeit der aus dem Prozess gewonnen Daten.',
    [Group.Datausage]: 'Das Kriterium „Datenverwendung“ bewertet, ob Prozessdaten durch andere Systeme genutzt werden können und zur datenbasierten Entscheidungsfindung herangezogen werden.',

    [Group.Description]: '„Prozessbeschreibung“ bewertet, ob eine Dokumentation des Prozesses und der darin enthaltenen Arbeitsabläufe mithilfe von Industriestandards in digitaler und maschinenlesbarer Form vorliegt.',
    [Group.Execution]: '„Ausführung“ bewertet, ob der Prozess stabil und transparent durchgeführt wird.',
    [Group.Compliance]: 'Das Kriterium „Compliance“ gibt darüber Auskunft, welche Kontroll- und Prüfinstanzen vorliegen und ob der Prozess alle externe Anforderungen an Datenschutz und -sicherheit erfüllt.',

    [Group.Centering]: 'Die „Zentrierung“ auf Nutzer:innen ist elementar für einen zielführenden Prozess. Der Wechsel in die Perspektive der Nutzer:innen ist unerlässlich.',
    [Group.Usage]: 'Der „Nutzen“ ist das zentrale Kriterium um einen Prozess für Nutzer:innen attraktiv zu gestalten.',
    [Group.Partizipation]: 'Das aktive Einbinden von Nutzer:innen in den Prozess führt dazu, Fehler und Probleme frühzeitig zu erkennen und den Prozess stetig zu verbessern.',

    [Group.DigitalSkills]: '„Digital Skills“ bewertet, ob in der Organisation die notwendigen Fähigkeiten vorhanden sind, um den Prozess durchzuführen und weiterzuentwickeln.',
    [Group.DigitalLeadership]: '„Digital Leadership“ bewertet, ob Führungskräfte in der Organisation über das nötige Know-How verfügen um digitale Prozesse zu verstehen und deren Weiterentwicklung sicherzustellen. Ebenso wird die Fähigkeit der Führungskräfte bewertet, die Mitarbeiter:innen zu befähigen.',
    [Group.DigitalMindset]: '„Digital Mindset“ bewertet, ob in der Organisation die richtige Kultur vorherrscht, um Digitalisierung ganzheitlich voranzutreiben. Dies betrifft nicht nur Mitarbeiter:innen und ihre Fähigkeiten, sondern im Besonderen die Führungskräfte und ihre Führungsqualitäten.',
}