import styles from './app-header.module.scss';
import aivot from '../../assets/aivot-logo.svg';
import {Container} from '../container/container';

export function AppHeader({className}: { className?: string }) {
    return (
        <div className={styles.wrapper + (className ? ' ' + className : '')}>
            <Container className={styles.container}>
                <h1 className={styles.title}>
                    Digitaler Reifegrad
                </h1>
                <div className={styles.logoItem}>
                    <a
                        href="https://aivot.de/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={aivot}
                            alt="Aivot UG"
                        />
                    </a>
                </div>
            </Container>
        </div>
    );
}
