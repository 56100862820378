import {techQuestions} from "./tech-questions";
import {Question} from "../../models/question";
import {dataQuestions} from "./data-questions";
import {qualityQuestions} from "./quality-questions";
import {customerQuestions} from "./customer-questions";
import {skillsQuestions} from "./skills-questions";

export const Questions: Question[] = [
    ...techQuestions,
    ...dataQuestions,
    ...qualityQuestions,
    ...customerQuestions,
    ...skillsQuestions,
];
