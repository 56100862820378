import styles from "./question-hero-card.module.scss";
import {CategoryColor} from "../../data/category/category-color";
import {CategoryLabel} from "../../data/category/category-label";
import {GroupLabel} from "../../data/group/group-label";
import {GroupDescription} from "../../data/group/group-description";
import {CategoryIllustration} from "../../data/category/category-illustration";
import {Card} from "../card/card";
import React from "react";
import {Questions} from "../../data/questions/questions";

export function QuestionHeroCard({idx, className}: { idx: number, className?: string }) {
    const question = Questions[idx];

    return (
        <Card className={className}>
            <div
                className={styles.hero}
                style={{backgroundColor: CategoryColor[question.category]}}
            >
                <h3 className={styles.categoryTitle}>
                    {CategoryLabel[question.category]}
                </h3>

                <h2 className={styles.groupTitle}>
                    {GroupLabel[question.group]}
                </h2>

                <p className={styles.description}>
                    {GroupDescription[question.group]}
                </p>

                <div className={styles.imgContainer}>
                    <img
                        src={CategoryIllustration[question.category]}
                        alt={CategoryLabel[question.category]}
                    />
                </div>
            </div>
        </Card>
    );
}