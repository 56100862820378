import styles from './intro.module.scss';
import {Button} from '../../components/button/button';
import {Container} from '../../components/container/container';
import {Card} from '../../components/card/card';
import {useAppDispatch} from '../../hooks';
import Illustration from '../../assets/digitalisierung.svg';
import {nextPage} from "../../features/app/app-slice";
import {Categories} from "../../data/category/categories";
import {CategoryLabel} from "../../data/category/category-label";
import {CategoryDescription} from "../../data/category/category-description";
import React from 'react';
import {CategoryColor} from "../../data/category/category-color";
import {Legal} from "../../components/legal/legal";


export function Intro() {
    const dispatch = useAppDispatch();

    const handleStart = () => {
        dispatch(nextPage());
    };

    return (
        <>
            <Container className={styles.heroContainer}>
                <div>
                    <Card className={styles.heroCard}>
                        <h2 className={styles.title}>
                            Digitaler Reifegrad
                        </h2>
                        <h5 className={styles.subtitle}>
                            Wie digital sind deine Geschäftsprozesse?
                        </h5>
                        <p>
                            <small>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.bitkom.org/Bitkom/Publikationen/Reifegradmodell-Digitale-Prozesse-2-0"
                                >
                                    Basierend auf dem Reifegradmodell 2.0 des Bitkom e.V.
                                </a>
                            </small>
                        </p>
                    </Card>
                    <div className={styles.actions}>
                        <Button
                            label="Jetzt analysieren"
                            onClick={handleStart}
                        />
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.title}>
                        Ist die Digitalisierung schon durch?
                    </h1>
                    <p style={{marginTop: 0}}>
                        <b>Cloud, Dev-Ops, Business Intelligence und Künstliche Intelligenz.
                        </b> Die Digitalisierung ist ein kontinuierlicher Prozess der eine Organisation ganzheitlich
                             betrifft und in fast alle Geschäftsprozesse eingreift.
                    </p>
                    <p>
                        Um so wichtiger ist es, dass man einen Überblick über den Digitalisierungsgrad der eigenen
                        Geschäftsprozesse hat.
                        So können Strategien und Schritte konkret geplant werden.
                        Der digitale Reifegrad betrachtet die fünf essentiellen Aspekte der Digitalisierung.
                    </p>

                    <div className={styles.phoneActions}>
                        <Button
                            label="Jetzt analysieren"
                            onClick={handleStart}
                        />
                    </div>
                </div>
            </Container>

            <Container>
                <div className={styles.twoColumn}>
                    <div className={styles.textPane}>
                        {
                            Categories.map(cat => (
                                <React.Fragment key={CategoryLabel[cat]}>
                                    <h3
                                        className={styles.categoryHeadline}
                                        style={{backgroundColor: CategoryColor[cat]}}
                                    >
                                        {CategoryLabel[cat]}
                                    </h3>
                                    <p>{CategoryDescription[cat]}</p>
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <div
                        className={styles.illustrationPane}
                    >
                        <img
                            src={Illustration}
                            alt="Beispiel für den digitalen Reifegrad"
                        />
                    </div>
                </div>
            </Container>

            <Container className={styles.ctaContainer}>
                <h4 className={styles.ctaHeadline}>
                    Bewerte jetzt den digitalen Reifegrad deiner Geschäftsprozesse!
                </h4>
                <p>
                    Mit der Selbstanalyse zum digitalen Reifegrad kannst du deine Prozesse auf die fünf Aspekte der
                    Digitalisierung überprüfen.
                </p>
                <div className={styles.ctaButtonContainer}>
                    <Button
                        label="Jetzt digitalen Reifegrad ermitteln"
                        onClick={handleStart}
                    />
                </div>
            </Container>

            <Container className={styles.legalContainer}>
                <Legal/>
            </Container>
        </>
    );
}
