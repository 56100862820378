import {Category} from "../category/category";
import {Group} from "../group/group";
import {ScoringType} from "../scoring-type";
import {Question} from "../../models/question";

export const dataQuestions: Question[] = [
    {
        category: Category.Data,
        group: Group.Datasourcing,
        title: 'Prozessdaten werden vollständig automatisiert erhoben.',
        hint: 'Prozessdaten sind beispielsweise Logdaten, Laufzeitinformationen etc.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Data,
        group: Group.Datasourcing,
        title: 'Prozessdaten werden vollständig digital archiviert.',
        hint: 'Prozessdaten sind beispielsweise Logdaten, Laufzeitinformationen etc. Eine digitale Archivierung ist immer revisionssicher.',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Data,
        group: Group.Dataprovision,
        title: 'Die Bereitstellung von Prozessdaten für das Berichtswesen ist vollständig digital.',
        hint: 'Eine vollständig digitale Bereitstellung meint v.a. auch eine automatisierte Bereitstellung mittels digitaler Schnittstelle. Beispiel: Das Ticketing-System für Supportanfragen sendet die Angaben der verarbeiteten Tickets automatisch per Schnittstelle an ein Data-Warehouse.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Data,
        group: Group.Dataprovision,
        title: 'Die visuelle Darstellung von Prozessdaten erfolgt strukturiert und nutzerfreundlich.',
        hint: '',
        type: ScoringType.Digitalization,
    },

    {
        category: Category.Data,
        group: Group.Datausage,
        title: 'Prozessdaten können vollständig über eine Schnittstelle für die externe Nutzung durch weitere Anwendungen bereitgestellt werden.',
        hint: 'Weitere Anwendungen können Werkzeuge für die Datenauswertung wie z.B. Business Intelligence sein.',
        type: ScoringType.Digitalization,
    },
    {
        category: Category.Data,
        group: Group.Datausage,
        title: 'Prozessdaten sind immer Grundlage zur Verbesserung des Geschäftsprozesses.',
        hint: '',
        type: ScoringType.Opinion,
    },
];