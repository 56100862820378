import {Category} from "./category";

export const CategoryDescription: {
    [key in Category]: string;
} = {
    [Category.Technology]: `
    Nur wenn Geschäftsprozesse konsequent Ende-zu-Ende digitalisiert sind kann die
    Wettbewerbsfähigkeit erhalten und sogar ausgebaut werden.
    `,

    [Category.Data]: `
    Daten, welche digital und in hoher Qualität vorliegen,
    ermöglichen tragfähige Entscheidungen. Organisationen ohne
    Datenkompetenz droht der Verlust ihrer Wettbewerbsfähigkeit.
    `,

    [Category.Quality]: `
    Schlechte Prozesse sind digital nicht besser.
    Gute Prozesse sind die Grundlage für jede erfolgreiche Organisation.
    `,

    [Category.Customers]: `
    Jeder Prozess muss die Anforderungen seiner jeweiligen
    Nutzer:innen erfüllen.
    Die Bedürfnisbefriedigung von Nutzer:innen ist die Daseins-Berechtigung
    einer jeden Organisation.
    `,

    [Category.Company]: `
    Die Digitalisierung ist nur zu einem kleinen Teil bestimmt von
    Software. Zum größten Teil ist sie bestimmt von Menschen und ihrem
    Denken und Handeln. Ein digitales Mindset bei allen Personen
    ist unerlässlich für eine erfolgreiche digitale Transformation.
    `,
}