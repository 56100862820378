import React, {useEffect, useRef} from 'react';
import {Intro} from './pages/intro/intro';
import {Process} from './pages/process/process';
import {Question} from './pages/question/question';
import {Summary} from './pages/summary/summary';
import {AppHeader} from './components/app-header/app-header';
import styles from './app.module.scss';
import {useAppDispatch, useAppSelector} from "./hooks";
import {AppPage} from './data/app-page';
import {AppActions} from "./components/app-actions/app-actions";
import {
    selectCurrentPage,
    selectShowErrors,
    setDescriptions,
    setPage,
    setProcessData,
    setScores
} from "./features/app/app-slice";
import {Questions} from "./data/questions/questions";

const pages: {
    [page in AppPage]: any;
} = {
    'intro': <Intro/>,
    'process': <Process/>,
    'summary': <Summary/>,
    0: <Question/>,
};

function App() {
    const dispatch = useAppDispatch();

    const currentPage = useAppSelector(selectCurrentPage);
    const showErrors = useAppSelector(selectShowErrors);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        switch (hash) {
            case 'started':
                dispatch(setPage('process'));
                break;
            case 'testing':
                dispatch(setProcessData({
                    processName: 'Testprozess',
                    processDescription: 'Der Digitale Reifegrad soll getestet werden.\n\nWir machen dazu folgendes:\n- Punkt 1\n- Punkt 2\n- Punkt 3',
                    users: '- Tester\n- Entwickler',
                    departments: '- Entwicklung\n- QS',
                    relatedProcesses: '- Feature-Entwicklung\n- Deployment',
                }));

                const scores = Questions.reduce((acc, _, idx) => ({...acc, [idx]: 1 + Math.floor(Math.random() * 5),}), {});
                dispatch(setScores(scores));

                const descriptions = Questions.reduce((acc, _, idx) => ({...acc, [idx]: 'Wir machen das in Excel.',}), {});
                dispatch(setDescriptions(descriptions));

                dispatch(setPage('summary'));
                break;
        }
    }, [dispatch]);

    useEffect(() => {
        if (contentRef.current != null) {
            contentRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [currentPage, showErrors]);

    return (
        <div className={styles.root}>
            <AppHeader
                className={styles.header}
            />

            <div
                className={styles.content}
                ref={contentRef}
            >
                {
                    typeof currentPage === 'string' ?
                        pages[currentPage] : pages[0]
                }
            </div>

            <AppActions
                className={styles.actions}
            />
        </div>
    );
}

export default App;
