import {Score} from "../data/score/score";
import {ScoreMap} from "../data/score-map";
import {Category} from "../data/category/category";
import {Questions} from "../data/questions/questions";
import {Group} from "../data/group/group";

const maxScore = Score.Level5;
const replaceNoDataScoreWith = Score.Level1;

export function calculateScore(scoresMap: ScoreMap, categoryFilter?: Category, groupFilter?: Group): number {
    const scores: (Score | null)[] = Questions.map((qs, index) => {
        if (categoryFilter != null && qs.category !== categoryFilter) {
            return null;
        }
        if (groupFilter != null && qs.group !== groupFilter) {
            return null;
        }
        let score = scoresMap[index] ?? Score.LevelNoData;
        if (score === Score.LevelNoData) {
            score = replaceNoDataScoreWith;
        }
        return score;
    });

    const cleanedScores: Score[] = scores.filter((sc): sc is number => sc != null);

    return cleanedScores
        .reduce((sum, score) => sum + score, 0)
        / maxScore
        / cleanedScores.length;
}