import {ScoreSelectProps} from "./score-select-props";
import styles from './score-select.module.scss';
import {Score} from "../../data/score/score";
import {cns} from "../../utils/class-names";
import {ScoringType} from "../../data/scoring-type";
import React from "react";
import {ScoreLabelDigitization} from "../../data/score/score-label-digitization";
import {ScoreLabelOpinion} from "../../data/score/score-label-opinion";
import {Scores} from "../../data/score/scores";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {nextPage, selectIsFastTrack, selectScore, selectShowErrors, setScore} from "../../features/app/app-slice";
import {Questions} from "../../data/questions/questions";

export function ScoreSelect({label, className, idx}: ScoreSelectProps) {
    const dispatch = useAppDispatch();

    const question = Questions[idx];

    const score = useAppSelector(selectScore(idx));
    const isFastTrack = useAppSelector(selectIsFastTrack);
    const showErrors = useAppSelector(selectShowErrors);

    const handleSelect = (score: Score) => {
        dispatch(setScore({idx, score}));
        if (isFastTrack) {
            setTimeout(() => {
                dispatch(nextPage());
            }, 250);
        }
    };

    return (
        <div className={className}>
            <h4
                className={cns({
                    [styles.label]: true,
                    [styles.error]: showErrors,
                })}
            >
                {label}
            </h4>

            <div className={styles.levelWrapper}>
                {
                    Scores.slice(1).map(sc => (
                        <button
                            key={sc}
                            onClick={() => handleSelect(sc)}
                            className={cns({
                                [styles.levelButton]: true,
                                [styles.active]: sc === score,
                            })}
                        >
                            {
                                question.type === ScoringType.Opinion ?
                                    ScoreLabelOpinion[sc] :
                                    ScoreLabelDigitization[sc]
                            }
                        </button>
                    ))
                }

                <button
                    onClick={() => handleSelect(Score.LevelNoData)}
                    className={cns({
                        [styles.levelButton]: true,
                        [styles.active]: Score.LevelNoData === score,
                        [styles.noData]: true,
                    })}
                >
                    {
                        question.type === ScoringType.Opinion ?
                            ScoreLabelOpinion[Score.LevelNoData] :
                            ScoreLabelDigitization[Score.LevelNoData]
                    }
                </button>
            </div>

            {
                showErrors &&
                <div className={styles.error}>
                    <small>
                        Bitte wähle eine der Optionen aus.
                    </small>
                </div>
            }
        </div>
    );
}