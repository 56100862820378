import {Category} from "../category/category";
import {Group} from "../group/group";
import {ScoringType} from "../scoring-type";
import {Question} from "../../models/question";

export const customerQuestions: Question[] = [
    {
        category: Category.Customers,
        group: Group.Centering,
        title: 'Der Prozess sieht die kontinuierliche Dokumentation der Bedürfnisse von Nutzer:innen vor.',
        hint: 'Die Dokumentation sollte in digitaler Form und jederzeit einsehbar vorliegen.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Customers,
        group: Group.Centering,
        title: 'Der Prozess sieht (zugeschnittene) Produkt- bzw. Serviceangebote für Nutzer:innen vor.',
        hint: '',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Customers,
        group: Group.Usage,
        title: 'Der Status des Prozesses ist jederzeit von außen (d. h. aus Sicht der Nutzer:innen) einsehbar.',
        hint: '',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Customers,
        group: Group.Usage,
        title: 'Die Nutzer:innen erkennen den Nutzen des digitalen Prozesses und wenden diesen an.',
        hint: '',
        type: ScoringType.Opinion,
    },

    {
        category: Category.Customers,
        group: Group.Partizipation,
        title: 'Der Prozess sieht verbindliche Beteiligungsformate für Nutzer:innen vor.',
        hint: 'Beteiligungsformate können beispielsweise Zufriedenheitsbefragungen, Feedback- oder Ideenmanagement sein.',
        type: ScoringType.Opinion,
    },
    {
        category: Category.Customers,
        group: Group.Partizipation,
        title: 'Es werden wirksame Maßnahmen ergriffen, um digitale Zugangsbarrieren im Prozess abzuschaffen.',
        hint: 'Maßnahmen zur Zugänglichkeit sind beispielsweise kontinuierliche Tests auf Barrierefreiheit, responsives Design und Wahrung von Web Accessibility Initiative (WAI) Standards.',
        type: ScoringType.Opinion,
    },
];