import TechnologyIllustration from "../../assets/technology.svg";
import DataIllustration from "../../assets/data.svg";
import QualityIllustration from "../../assets/quality.svg";
import CustomerIllustration from "../../assets/customer.svg";
import CompanyIllustration from "../../assets/company.svg";
import {Category} from "./category";

export const CategoryIllustration: {
    [key in Category]: string;
} = {
    [Category.Technology]: TechnologyIllustration,
    [Category.Data]: DataIllustration,
    [Category.Quality]: QualityIllustration,
    [Category.Customers]: CustomerIllustration,
    [Category.Company]: CompanyIllustration,
}