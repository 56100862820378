import React from 'react';
import {useAppSelector} from '../../hooks';
import {Container} from '../../components/container/container';
import styles from './summary.module.scss';
import {selectProcessData, selectScores} from "../../features/app/app-slice";
import {Chart} from "../../components/chart/chart";
import {Categories} from "../../data/category/categories";
import {CategorySummary} from "../../components/category-summary/category-summary";
import {calculateScore} from "../../utils/calculate-score";

export function Summary() {
    const process = useAppSelector(selectProcessData);
    const scores = useAppSelector(selectScores);

    const averageScore = calculateScore(scores);

    return (
        <>
            <Container>
                <h1>
                    {process.processName}
                </h1>
                <h3>
                    Digitalisierungsgrad:
                    <span className={styles.score}>{Math.floor(averageScore * 100)}%</span>
                </h3>

                <div className={styles.chartContainer}>
                    <Chart/>
                </div>

                <div className={styles.infoContainer}>
                    <div>
                        <h4 className={styles.title}>Prozessname</h4>
                        <p className={styles.content}>{process.processName}</p>
                    </div>
                    {
                        process.processDescription.trim().length > 0 &&
                        <div>
                            <h4 className={styles.title}>Kurzbeschreibung des Geschäftsprozesses</h4>
                            <pre className={styles.content}>{process.processDescription}</pre>
                        </div>
                    }
                    {
                        process.departments.trim().length > 0 &&
                        <div>
                            <h4 className={styles.title}>Beteiligte Organisationseinheiten</h4>
                            <pre className={styles.content}>{process.departments}</pre>
                        </div>
                    }
                    {
                        process.users.trim().length > 0 &&
                        <div>
                            <h4 className={styles.title}>Beteiligte Nutzer:innen</h4>
                            <pre className={styles.content}>{process.users}</pre>
                        </div>
                    }
                    {
                        process.relatedProcesses.trim().length > 0 &&
                        <div>
                            <h4 className={styles.title}>Verwandte Prozesse</h4>
                            <pre className={styles.content}>{process.relatedProcesses}</pre>
                        </div>
                    }
                </div>

                <div className={styles.categoriesContainer}>
                    <h2>
                        Aufschlüsselung
                    </h2>

                    {
                        Categories.map(cat => (
                            <CategorySummary
                                key={cat}
                                category={cat}
                                className={styles.categorySummary}
                            />
                        ))
                    }
                </div>
            </Container>
        </>
    );
}
