import {FormFieldProps} from "./form-field-props";
import {cns} from "../../utils/class-names";
import styles from "./form-field.module.scss";
import React, {ChangeEvent} from "react";


export function FormField<T>({field, container, onChange, error, className}: FormFieldProps<T>) {
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // @ts-ignore
        onChange({
            [field.field]: event.target.value,
        });
    };

    return (
        <div
            key={field.label}
            className={cns({
                [styles.inputContainer]: true,
                [styles.error]: error != null,
                [className ?? '']: className != null,
            })}
        >
            <label
                htmlFor={field.field.toString()}
                className={cns({
                    [styles.label]: true,
                    [styles.required]: field.required,
                })}
            >
                {field.label}
            </label>
            {
                (field.type === 'textarea' || field.type === 'textarea-large') ? (
                    <textarea
                        id={field.field.toString()}
                        className={styles.input}
                        value={container[field.field] as string ?? ''}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        rows={field.type === 'textarea-large' ? 10 : 5}
                        aria-describedby={field.field.toString() + '-hint'}
                    ></textarea>
                ) : (
                    <input
                        id={field.field.toString()}
                        className={styles.input}
                        value={container[field.field] as string ?? ''}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        aria-describedby={field.field.toString() + '-hint'}
                    />
                )
            }
            <div
                id={field.field.toString() + '-hint'}
                className={styles.hint}
            >
                <small>{field.hint}</small>
            </div>
            {
                error &&
                <div className={styles.error}>
                    <small>{error}</small>
                </div>
            }
        </div>
    );
}