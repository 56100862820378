import styles from "./legal.module.scss";

export function Legal() {
    return (
        <div className={styles.links}>
            <a
                href="https://aivot.de/impressum"
                target="_blank"
                rel="noreferrer"
            >
                Impressum
            </a>
            <span>|</span>
            <a
                href="https://aivot.de/datenschutz"
                target="_blank"
                rel="noreferrer"
            >
                Datenschutz
            </a>
        </div>
    );
}