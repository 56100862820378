import styles from './progress.module.scss';
import {useAppSelector} from "../../hooks";
import {selectCurrentPage} from "../../features/app/app-slice";
import {Questions} from "../../data/questions/questions";

export function Progress() {
    const currentPage = useAppSelector(selectCurrentPage);

    const progress = ((typeof currentPage === 'string' ? 0 : currentPage) + 1) / Questions.length * 100;

    return (
        <div className={styles.container}>
            <div
                className={styles.bar}
                style={{width: `${progress}%`}}
            ></div>
        </div>
    );
}