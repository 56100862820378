import {ButtonProps} from './button-props';
import styles from './button.module.scss';

export function Button({label, className, ...props}: ButtonProps) {
    return (
        <button
            className={styles.button + (className ? ' ' + className : '')}
            onClick={props.onClick}
        >
            {label}
        </button>
    );
}
